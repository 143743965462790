import { React, useLayoutEffect } from "react";
import { SectionTitle } from "../components/SectionTitle";
import { ContactSection } from "../sections/ContactSection";
import LegsForDays from "../assets/gallery/LegsForDays.jpeg";

import { FaCheck, FaX } from "react-icons/fa6";

import {
  SessionPageContainer,
  InPersonSessionImage,
  IntroArea,
  IntroTextContainer,
  SessionImageContainer,
  ContentArea,
  TextContainer,
  SubTitle,
  StepTitle,
  TributesWrapper,
  BiggerText,
  TertiaryTitle,
  IncludedWrapper,
  TableContainer,
  Column,
} from "../components/SessionPageComponents";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const InPersonSession = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SessionPageContainer>
      <ContentArea>
        <SectionTitle title="Sessions" />

        <SubTitle>Amsterdam</SubTitle>

        <br />
        <IntroArea>
          <SessionImageContainer>
            <InPersonSessionImage image={LegsForDays} />
          </SessionImageContainer>
          <IntroTextContainer>
            Power Dynamics is my specialty, and 'special spot' when it comes to
            FemDom. I love to create a slow-motion, hypnotic vibe through
            seduction and and sensuality and gradually amp up intensity. I'm a
            passionate about building the fricction between desire & denial,
            tension & laughter, agony & ecstasy... With precision, care, and a
            lot of kinky lust, I seduce my preys into submission. And once I win
            you over, I will use you like the little play tool I made you to be.
            Getting your total surrender is just the beginning for Me - and the
            end of you.
            <br />
            Here are some examples of techniques I use to achieve that:
            <ul>
              <li>Clear expectations and safewords</li>
              <li>Power Play and Total Power Exchange</li>
              <li>Gorgeous outfits in leather, latex and high heels</li>
              <li>Bondage, specially using rope</li>
              <li>A mix of soft touches with hard core spanking</li>
              <li>Dirty talk, humiliation and mindfucks</li>
              <li>Pure, primal, unfiltered Sadism</li>
              <li>A touch of Female Supremacy</li>
              <li>Worship, devotion and surrender to Me</li>
              <li>Play with My glorious, gorgeous, sensitive Feet</li>
              <li>
                <Link
                  to="/sessions/list-of-practices"
                  style={{ textDecoration: "underline" }}
                >
                  Check here a more detailed list of practices I enjoy
                </Link>
              </li>
            </ul>
          </IntroTextContainer>
        </IntroArea>

        <br />
        <br />
        <SubTitle>In Practical Terms...</SubTitle>

        <TertiaryTitle>What to expect</TertiaryTitle>
        <BiggerText>
          <FaCheck /> All your <strong>boundaries and limits</strong> respected
        </BiggerText>
        <BiggerText>
          <FaCheck /> A Mistress who is truly passionate about Fetish, BDSM,
          FemDom and Power Dyanmics.
        </BiggerText>
        <BiggerText>
          <FaCheck /> I don't work with scripts (unless in the context of a role
          play). I make preparations and plans, but during the session, I go
          with the flow, and do what feels good for me, and right for your
          training.
        </BiggerText>
        <BiggerText>
          <FaCheck /> <strong>Safewords</strong>: You will receive safewords
          which you can call at any time of the session. They will signal to Me
          something that is a potential boundary, and some other important
          details mid-session.
        </BiggerText>
        <BiggerText>
          <FaCheck /> I strive to provide a
          <strong> Premium experience in FemDom</strong> for anyone who books
          me. Politeness, discretion and a touch of formality are some of the
          values I carry myself as a professional. You will have access to
          top-notch skills, high-quality accessories, attention to hygiene and
          all other details that make for a smooth and memorable experience.
        </BiggerText>
        <BiggerText>
          <FaCheck /> A <strong>personal touch </strong>is also always present
          in dynamics with Me. I prefer to cultivate long-term relationships,
          than have a more 'one-off', 'transactional' approach. And I select
          submissives with the same mindset.
        </BiggerText>
        <BiggerText>
          <FaCheck /> <strong>Aftercare :)</strong> Once our session is done,
          the last minutes will be reserved for 'cool down', talk to each other,
          check in on how each other feel, just to make sure we are both
          'grounded' before venturing back to the 'outside world'.
        </BiggerText>

        <div>
          <TertiaryTitle>What NOT to expect:</TertiaryTitle>
          <BiggerText>
            <FaX /> Don't feel entitled to an Orgasm: yes, it is frequent that
            submissives orgasm during sessions. However this will not be the
            focus of the session. If that happens, it should be seen as gift for
            good behaviour, not as a box to be 'checked'.
          </BiggerText>
          <BiggerText>
            <FaX /> Sex - including oral. If you expect sex from a session with
            a Dominatrix, you must be widly misinformed. You are entitled to
            nothing but my time, and having your boundaries respected. If that
            doesn't work for you, we are not a match.
          </BiggerText>
          <BiggerText>
            <FaX /> Intoxication: please refrain from alcohol use, weed,
            psychodelics, or any other non-prescribed substances prior to our
            session. It's okay to have a glass of wine after the session while
            we talk, but it is important to be sober during the experience.
            There are several reasons for this, which I can gladly share in out
            intro phone call if needed.
          </BiggerText>
          <BiggerText>
            <FaX /> Any disrespect to my boundaries, unwelcomed touching or
            behaviour unsuitable for a submissive are not going to be tolerated.
            If you make me feel uncomfortable or disrepect my boundaries, I will
            leave and take my tribute with me.
          </BiggerText>
          <BiggerText>
            <FaX /> No last-minute sessions. I'm not 'on call'. My appointments
            are scheduled, confirmed, and very well-prepared.
          </BiggerText>
          <BiggerText>
            <FaX /> No late-night sessions [between 10pm and 11am], unless for
            overnights, scheduled in advance.
          </BiggerText>
        </div>
        <br />
        <br />
        <TributesWrapper>
          <SubTitle>Amsterdam Tributes</SubTitle>
          <IncludedWrapper>
            <Column>
              <TertiaryTitle>What IS included:</TertiaryTitle>
              <BiggerText>
                <FaCheck /> All session practices: no mid-session fees or
                "surprise fees"
              </BiggerText>
              <BiggerText>
                <FaCheck /> Any transportations costs within Amsterdam: no "taxi
                fees"
              </BiggerText>
              <BiggerText>
                <FaCheck /> The use of all sex toys and accessories - brought by
                Me to the session, hand-picked from my extensive collection
              </BiggerText>
              <BiggerText>
                <FaCheck /> A 20min intro audio call prior to our first session
                to get to know each other a bit, discuss interests, boundaries,
                and clarify any questions.
              </BiggerText>
            </Column>
            <Column>
              <TertiaryTitle>What is NOT included:</TertiaryTitle>
              <BiggerText>
                <FaX /> Hotel/Studio costs: long story short - it's a legal
                thing within The Netherlands. By booking the room yourself, we
                are 100% compliant. I'm happy to provide recommendations.
              </BiggerText>
              <BiggerText>
                <FaX /> Couples/Duos: please consult Me on Tributes for this
                case.
              </BiggerText>
              <BiggerText>
                <FaX /> Oral or penetrative sex is not something I offer. The
                closest it gets to this is me using a strap-on on you.
              </BiggerText>
            </Column>
          </IncludedWrapper>
          <br />
          <TableContainer>
            <table>
              <tbody>
                <tr>
                  <td>2h</td>
                  <td>600 €</td>
                </tr>
                <tr>
                  <td>3h</td>
                  <td>900 €</td>
                </tr>
                <tr>
                  <td>6h</td>
                  <td>1600 €</td>
                </tr>

                <tr>
                  <td>16h</td>
                  <td>2500 €</td>
                </tr>
                <tr>
                  <td>24h</td>
                  <td>3000 €</td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <br />
        </TributesWrapper>

        <SubTitle>Admission Process</SubTitle>

        <TextContainer>
          <StepTitle>1 - Fill in My Application Form</StepTitle>

          <StepTitle>
            2 - If you're approved, you'll send a deposit to lock in your time
          </StepTitle>

          <StepTitle>3 - Pre-scheduled 20min intro audio call </StepTitle>

          <StepTitle>4 - Play time!</StepTitle>
        </TextContainer>
        <br />
        <SubTitle>Do you Dare to Surrender?</SubTitle>

        <ContactSection noPaddings={true} />
      </ContentArea>
    </SessionPageContainer>
  );
};
