import styled from "styled-components";

export const SessionPageContainer = styled.div`
  margin: 5.5rem 2rem 2.5rem 2rem;

  @media screen and (max-width: 500px) {
    margin: 1rem 1.5rem;
  }
`;

export const ContentArea = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);

  @media screen and (max-width: 500px) {
    margin-top: 6.4rem;
  }
`;

export const IntroArea = styled.div`
  display: flex;
  gap: 50px;

  @media screen and (max-width: 600px) {
    flex-direction: ${(props) =>
      props.invertOrderOnMobile ? "column-reverse" : "column"};
  }
`;

export const SessionImageContainer = styled.div`
  width: 50%;

  overflow: hidden;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const IntroTextContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div``;

export const CenteredText = styled.div`
  text-align: center;
`;

export const TributesWrapper = styled.div`
  background-color: #222;
  padding: 2rem;
  margin-bottom: 4rem;
  width: 100%;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`;

export const BiggerText = styled.div`
  font-size: 20px;
  /* text-align: center; */
  margin-bottom: 0.8rem;
  @media screen and (max-width: 600px) {
    text-align: left;
  }
`;

export const IncludedWrapper = styled.div`
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
  width: 50%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const TertiaryTitle = styled.p`
  font-size: x-large;
  text-align: center;
`;

export const InPersonSessionImage = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 80vh;
`;

export const SubTitle = styled.h2`
  font-family: "Ysabeau";
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
`;

export const StepTitle = styled.h3``;

export const StepDescription = styled.p``;
