import { SessionAccordions } from "../components/Accordions";
import { SectionContainer } from "../components/SectionContainer";
import { SectionTitle } from "../components/SectionTitle";
import { BiggerText } from "../components/SessionPageComponents";

const ListOfPractices = () => {
  return (
    <SectionContainer>
      <SectionTitle title="Practices" />
      <br />
      <BiggerText>
        Some experiences are beyond classification But here are some teasers for
        your imagination from what I enjoy enforcing:
      </BiggerText>
      <br />
      <SessionAccordions />
    </SectionContainer>
  );
};

export default ListOfPractices;
