import { React, useLayoutEffect } from "react";
import {
  ContentArea,
  InPersonSessionImage,
  IntroArea,
  IntroTextContainer,
  SessionImageContainer,
  SessionPageContainer,
  SubTitle,
} from "../components/SessionPageComponents";
import MentoringImage from "../assets/gallery/2.jpeg";
import { SectionTitle } from "../components/SectionTitle";
import {
  ContactSubTitle,
  TextContainer,
  TextQuote,
  AllSocialsContainer,
  StyledLink,
  SocialContainer,
} from "../sections/ContactSection";
import { SiProtonmail } from "react-icons/si";

export const MentoringPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SessionPageContainer>
      <ContentArea>
        <SectionTitle title="Mentoring" />
        <br />
        <IntroArea>
          <SessionImageContainer>
            <InPersonSessionImage image={MentoringImage} />
          </SessionImageContainer>
          <IntroTextContainer>
            What would you like to learn from a Professional Dominatrix? How to
            whip your submissives safely? How to command a room? Or maybe how to
            become a Pro Domme yourself? Or maybe you simply would like to talk
            to someone who's have "been there, done that" a challenging moment
            in your kinky journey? I got you.
            <br />
            <br />
            After a few successful test-sessions, I'm happy to announce that I'm
            now offering Mentoring Sessions. The sessions are tailored to your
            needs, wants and curiosity, and have very friendly prices of
            100/hour (compared to session tributes). Not calling it a Tribute,
            because we won't be in a D/s dynamic through this. The idea is to
            offer a tailored experience for you where I can share some of my
            knowledge and experience acquired through my own journey.
            <br /> <br /> If you'd like an online mentoring session, feel free
            to DM me as well and we can work something out.
          </IntroTextContainer>
          <br />
        </IntroArea>
        <br />
        <SubTitle>How does it work?</SubTitle>

        <TextContainer>
          1- Reach out through email telling me a bit about yourself, and what
          would you like to talk about. Examples below.
          <br />
          2- If the mentoring includes some in-scene parts (i.e. "how to use a
          flogger safely"), you will need to provide a space for that yourself.
          If it's just a talk, we can meet somewhere like a quiet area of a
          coffee place, restaurant or even a park.
          <br />3 - I'll show up to the date and time we agreed on and we'll
          talk. Everything in we talk about it totally confidential, and you can
          expect complete discretion from me.
          <br />4 - If the mentoring session involves practical in-scene parts,
          you may bring a "punching bag" submissive, to be our guinea pig for
          the session. If you don't have one, let me know and that can be
          arranged.
        </TextContainer>

        <SubTitle>Examples</SubTitle>
        <TextContainer>
          Scene-related:
          <ul>
            <li>Non-verbal communication for Dominance</li>
            <li>
              Safety, technique and grace on: flogging, bondage, foot play etc
            </li>
            <li>
              How to improve yourself as a Top: techniques and human connection
            </li>
            <li>Anything consent-related</li>
            <li>Trauma & BDSM</li>
          </ul>
          Work-related
          <ul>
            <li>
              Using FemDom as leverage for Workplace Empowerment for women
            </li>
            <li>
              Verbal communication for Dominance in difficult conversations
            </li>
            <li>Becoming a Pro Domme: challenges and opportunities</li>
            <li>
              Privacy, data, payment systems and digital challenges for Pro
              Dommes
            </li>
          </ul>
          Love & Intimacy
          <ul>
            <li>Navigating Kink & Polyamory</li>
            <li>BDSM inside Love relationships</li>
            <li>Love inside BDSM relationships</li>
          </ul>
        </TextContainer>
        <SubTitle>What I bring</SubTitle>
        <ul>
          <li> 6y+ of full time experience in Professional FemDom</li>
          <li>Academic Research Thesis around Power Dynamics & Society</li>
          <li>Vanila workplace experience in the Netherlands and abroad</li>
          <li>
            First-hand experience in BDSM in love relationships and polyamory
          </li>
          <li>Trauma-informed, LGBT+ inclusive approach</li>
          <li> Couples, trouples or policules welcome</li>
        </ul>
        <SubTitle>What Mentoring Sessions do NOT include</SubTitle>
        <ul>
          <li>
            No FemDom from me. We won't be in a D/s dynamic. This is not a
            FemDom session.
          </li>
          <li>
            I'm not a licensed therapist, and these sessions are not meant to
            replace therapy.
          </li>
          <li>
            Don't expect me to show up in full-on latex-and-leather Dominatrix
            outfits
          </li>
          <li>A place for the session, if required, is also on you.</li>
        </ul>

        <br />
        <br />
        <SectionTitle title="Contact" />
        <TextContainer>
          <ContactSubTitle>
            {" "}
            Introduce yourself properly. Let me know a bit about yourself and
            makes sure to include:
          </ContactSubTitle>
          <ul>
            <li>Your interests</li>
            <li>Your boundaries / hard limits</li>
            <li>
              Health issues that could affect the dynamic (i.e: post-surgery, a
              'bad knee', asthma etc){" "}
            </li>
            <li>Dates you'd be available for the session</li>
          </ul>
          <ContactSubTitle>
            Only half of the initial messages I receive are minimally
            intelligible. The others, I don't bother to reply to. Pick your
            half.
          </ContactSubTitle>
          <ContactSubTitle>
            Here is an example of an acceptable message. Feel free to use it as
            a template:
          </ContactSubTitle>
          <TextQuote>
            "Hello Maya,
            <br /> My name is _____. I'm visiting Amsterdam next week and me and
            my partner would love a Mentoring session to learn how to introduce
            FemDom into our relationship. Could you please let us know your
            availability?
            <br />
            Looking forward to hearing back from you."
          </TextQuote>
          <br />

          <AllSocialsContainer>
            <StyledLink href="mailto:mayamistress@protonmail.com">
              <SocialContainer>
                <SiProtonmail className="social-icon" />
                Contact Me via Email
              </SocialContainer>
            </StyledLink>
          </AllSocialsContainer>
        </TextContainer>
      </ContentArea>
    </SessionPageContainer>
  );
};
