import styled from "styled-components";
import { CopyRightsFooter, StyledLink } from "../sections/ContactSection";

import LogoImage from "../assets/logos/LogoMayaMedium.png";

import { SiInstagram, SiTwitter } from "react-icons/si";
import { TiHeart } from "react-icons/ti";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <div style={{ textAlign: "center" }}>
          <FooterLogoContainer>
            <Logo />
          </FooterLogoContainer>
          Official Socials:
          <AllSocialsContainer>
            <StyledLink href="https://instagram.com/missmayadeville">
              <SocialContainer>
                <SiInstagram className="social-icon" />
                Instagram
              </SocialContainer>
            </StyledLink>
            <StyledLink href="https://x.com/MayaMistress_">
              <SocialContainer>
                <SiTwitter className="social-icon" />
                Twitter / X
              </SocialContainer>
            </StyledLink>
            <StyledLink href="https://fetlife.com/users/6290666">
              <SocialContainer>
                <TiHeart className="social-icon" />
                FetLife
              </SocialContainer>
            </StyledLink>
          </AllSocialsContainer>
        </div>

        <a
          href="http://www.dickievirgin.com/"
          target="_blank"
          width={"100%"}
          rel="noreferrer"
        >
          <img
            src="http://www.dickievirgin.com/banner/dickie-main-standard.gif"
            alt="dickie virgin FemDom guide"
            width="100%"
          />
        </a>
        <a
          href="https://www.mistress-guide.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://www.mistress-guide.com/images/ourpics/mistress_guide_240x90_1.gif"
            alt="verified Mistress Guide"
            title="verified Mistress Guide"
            border="0"
          />
        </a>
        <a href="https://www.domina.guide/" target="_blank" rel="noreferrer">
          <img
            src="https://www.domina.guide/domina-guide.jpg"
            alt="Dominatrixes and BDSM Directory"
            width={"100%"}
          />
        </a>
        <a
          href="https://www.eliteukmistresses.co.uk"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://www.eliteukmistresses.co.uk/wp-content/uploads/1eliteukmistresses.gif"
            alt="Elite Mistresses UK"
            width={"100%"}
          />
        </a>
        <a href="http://www.fetishlink.co.uk" target="_blank" rel="noreferrer">
          <img
            src="https://www-punterlink-co-uk.dualstackcdn.com/fetish/images/siteban/fetban07.jpg"
            alt="Fetish Link UK"
            width={"100%"}
          />
        </a>
        <a
          href="https://www.22burlington.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://www.22burlington.com/images/banners/22burlington-trans-white.png"
            alt="Fetish Link UK"
            width={"100%"}
            style={{ maxWidth: "450px" }}
          />
        </a>
      </FooterContent>
      <CopyRightsFooter>
        <br />
        Copyright © {new Date().getFullYear()} | All rights reserved to Maya
        Mistress
        <br />
      </CopyRightsFooter>
    </FooterContainer>
  );
};

const FooterLogoContainer = styled.div`
  height: 60px;
  margin-bottom: 34px;
`;

const Logo = styled.div`
  background-image: url(${LogoImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const FooterContainer = styled.div`
  background-color: #111;
  color: white;
  width: 100%;
  padding-top: 10px;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  flex-wrap: wrap;

  margin: 0 auto;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 600px) {
    max-width: 300px;
    padding: 0rem;
  }
`;

export const SocialContainer = styled.div`
  font-size: 16px;
  padding: 1rem 0;
  color: white;
  font-family: "Kumbh Sans", sans-serif;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem 1.5rem;
  width: 250px;
  margin: 10px;
  text-align: center;

  @media screen and (max-width: 700px) {
    padding: 1rem 0;
    font-size: 1rem;
    width: 300px;
  }
`;

export const AllSocialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem 3rem 0rem;

  .social-icon {
    margin-right: 1rem;
    flex-direction: column;
  }

  @media screen and (max-width: 700px) {
    height: auto;
    font-size: 1rem;

    .social-icon {
      margin-right: 0.5rem;
    }
  }
`;
